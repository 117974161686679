<template lang="pug">
  div
    TheHeader
    .wrapper.policy
      .policy_wrap
        h2 {{ $t('legalDocs.copyrightNotice.title') }}
        .policy_updated {{ $t('legalDocs.lastUpdated') }}: {{ updatedLegal(updatedAt, this.$i18n.locale) }}
        p {{ $t('legalDocs.copyrightNotice.introduction') }}
        div(v-html="$t('legalDocs.copyrightNotice.list')")
        div(v-html="$t('legalDocs.copyrightNotice.notice')")
        div(v-html="$t('legalDocs.copyrightNotice.counterNotificationProcedure')")
        div(v-html="$t('legalDocs.copyrightNotice.repeatInfringers')")
</template>

<script>
import TheHeader from '@/components/TheHeader';
import { updatedLegal } from '@/dates';

export default {
  components: { TheHeader },
  data() {
    return { updatedAt: '2020-01-16' };
  },
  methods: {
    updatedLegal(date, locale) {
      return updatedLegal(date, locale);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .policy {
    margin: 0 auto;
    padding: 140px 20px 80px;
    text-align: left;
    width: 100%;
    color: $sub-black;

    &_wrap {
      max-width: 800px;
      margin: 0 auto;
    }

    &_updated {
      margin: 20px 0;
      font-style: italic;
    }

    h2 {
      font-size: 38px;
      font-weight: 700;
    }

    a {
      text-decoration: none;
      color: $orange;
    }

    &_subheader {
      font-size: 24px;
      font-weight: 700;
      margin: 15px 0;
    }

    :global(ul) { list-style-type: circle !important; }

    p,
    li {
      font-size: 22px;
      line-height: 2;
      font-weight: 500;
    }

    ul > li { margin: 10px 0; }
  }
</style>
